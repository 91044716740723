@import '~antd/dist/antd.less';

.app-container {
  height: 100vh;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.site-layout .site-layout-background {
  background: #fff;
}

.site-layout-background {
  overflow-y: scroll;
}

.error-message {
  color: red;
  display: flex;
  justify-content: center;
}

.content-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;

  .icon {
    margin-right: 8px;
  }
}

.content-form {
  max-width: 500px;
}

.button-group {
  padding-top: 24px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  >Button {
    margin-bottom: 8px;
  }
}

.button-group,
.form-button {
  Button {
    width: 100px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.form-button {
  margin-top: 12px;
}

.breadcrumb {
  padding-bottom: 24px !important;
}

.ant-row-style {
  cursor: pointer;
}

.ant-row-style1 {
  cursor: pointer;
  background: #8db600 !important;
}

.app-form-container {
  max-width: 1024px;
  padding: 28px;
  margin: 20px auto;
  border-radius: 5px;
  background-color: white;

  .app-form-horizontal-fields {
    display: flex;

    >div {
      flex: 1;
    }

    >div:not(:last-child) {
      padding-right: 16px;
    }
  }

  @media (max-width: 768px) {
    max-width: 680px;
    margin-top: 0;
  }

  @media (max-width: 576px) {
    margin: auto;

    .app-form-horizontal-fields {
      flex-direction: column;

      >div:not(:last-child) {
        padding-right: 0;
      }
    }
  }
}

.app-form-small-container {
  &:extend(.app-form-container);
  max-width: 576px;
}

.ant-pagination {
  text-align: right;
}

.filter-panel {
  margin-bottom: 8px;

  >div {
    margin: 8px !important;
  }

  .filter-button-group {
    width: 100%;

    Button {
      width: 100px !important;
      margin-right: 8px !important;

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }
}

.long-button {
  min-width: 200px !important;
}

.statistic-container {
  background-color: @primary-2;
  padding: 8px;
  margin-top: 8px;
  display: flex;

  div {
    padding: 0 16px 0 4px;
  }

  span {
    font-weight: bold;
  }
}

.ant-table{
  font-size: 12px;
}

// .ant-table-tbody>tr.ant-table-row:hover>td {
//   background: #046CC1 !important;
// }

// .ant-table-tbody>tr>td.ant-table-cell-row-hover {
//   background: #046CC1 !important;
// }

.ant-descriptions-item-label {
  font-size: 12px;
  padding: 12px 18px !important;
}

.ant-descriptions-item-content {
  font-size: 12px;
  padding: 12px 18px !important;
}

.jodit-react-container {
  margin-bottom: 15px;
  
  .jodit-workplace {
    min-height: 200px !important;
  }
}

.confirmation-msg-alert-span{
  color: red;
  font-style: italic;
  font-size: 14px;
  font-weight: 600;
}

.negative-number {
  color: red;
}
@primary-color: #046CC1;@secondary-color: #ffce54;@link-color: #1890ff;@background-color: #ffffff;@background-color-1: lightblue;@background-color-2: gainsboro;@background-color-3: blueviolet;@background-color-4: antiquewhite;@background-color-5: limegreen;@background-color-8: #000000;@background-color-9: #040162;@txt-color-1: black;@txt-color-2: white;@txt-color-3: blue;@txt-color-4: #198754;@txt-color-5: #DC3545;@txt-color-6: #747c8b;@txt-color-9: #aaa;@border-color-1: black;@border-color-2: #d9d9d9;@border-color-4: #dddddd;@border-color-8: #000000;@btn-bg-color-1: #ebb23d;@btn-bg-color-3: #e79235;@button-text: #ffffff;@menu-txt-color: #666666;