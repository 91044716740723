.site-layout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff !important;
    
    .header-info-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 15px;
        padding: 0 24px;

        .home-promo-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            max-height: 30px;

            .promo-title {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px 10px;
            }

            .promo-url {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px 10px;
                background-color: @background-color-4;
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
                max-height: 30px;
            }

            .promo-copy-btn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 15px;
                background-color: @primary-color;
                max-height: 30px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                color: @txt-color-2
            }
        }

        .balance-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .balance {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;
                font-weight: 600;
                font-size: 16px;
            }
        }
        
        .referral-btn-container {
            margin-right: 10px;
            
            .referral-btn {
                cursor: pointer;
                background-color: @secondary-color;
                padding: 0px 10px;
            }
        }

        .profile-menu {
            float: left;
            padding: 0 6px;

            .profile-menu-title {
                padding: 0 0 0 3px;
            }

        }
    }
   
}

.refferal-code-modal {
    .refferal-code-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        .qr-instruction {
            padding: 15px 0px;
            background-color: @secondary-color;
            width: 100%;

            .instruction-txt {
                text-align: center;
                text-transform: uppercase;
                font-size: 18px;
                font-weight: 600;
            }
        }

        .share-copy-btn {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            .share-link {
                font-weight: 600;
            }

            .copy-btn {
                cursor: pointer;
                text-transform: uppercase;
                padding: 10px 20px;
                border-radius: 5px;
                font-weight: 600;
                background-color: blue;
                color: white
            }
        }
    }
}

@media only screen and (max-width: 600px) {
    .site-layout-header {

        >div .profile-menu .profile-menu-title {
            display: none;
            
        }
    }
}
@primary-color: #046CC1;@secondary-color: #ffce54;@link-color: #1890ff;@background-color: #ffffff;@background-color-1: lightblue;@background-color-2: gainsboro;@background-color-3: blueviolet;@background-color-4: antiquewhite;@background-color-5: limegreen;@background-color-8: #000000;@background-color-9: #040162;@txt-color-1: black;@txt-color-2: white;@txt-color-3: blue;@txt-color-4: #198754;@txt-color-5: #DC3545;@txt-color-6: #747c8b;@txt-color-9: #aaa;@border-color-1: black;@border-color-2: #d9d9d9;@border-color-4: #dddddd;@border-color-8: #000000;@btn-bg-color-1: #ebb23d;@btn-bg-color-3: #e79235;@button-text: #ffffff;@menu-txt-color: #666666;