.side-nav-logo {
    width: 100%;
    max-height: 64px;
    height: 64px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: beige;

    >img {
        margin: 10px;
        max-width: 88px;
        width: 100%;
    }

    .logo-name {
        color: white;
        white-space: nowrap;
        min-width: 0;
        opacity: 1;
        position: absolute;
        left: 64px;
        top: 19.5px;
        z-index: 0;
    }
}

@media only screen and (min-width:481px) {
    .ant-layout-sider-collapsed .side-nav-logo.collapsed {
        width: 100%;
        align-items: center;
        max-height: 64px;
        height: 64px;

        >img {
            margin: 10px;
            max-width: 44px;
            width: 100%;
        }

        .logo-name {
            width: 0;
            opacity: 0;
            display: none;
        }
    }
}

@media only screen and (max-width: 480px) {
    .ant-layout-sider-collapsed {
        flex: 0 !important;
        width: 0 !important;
        max-width: 0 !important;
        min-width: 0 !important;

        .side-nav-logo.collapsed .logo-name {
            display: none;
        }
    }
}

.ant-badge-count{
    line-height: 15px;
    height: 15px;
}
@primary-color: #046CC1;@secondary-color: #ffce54;@link-color: #1890ff;@background-color: #ffffff;@background-color-1: lightblue;@background-color-2: gainsboro;@background-color-3: blueviolet;@background-color-4: antiquewhite;@background-color-5: limegreen;@background-color-8: #000000;@background-color-9: #040162;@txt-color-1: black;@txt-color-2: white;@txt-color-3: blue;@txt-color-4: #198754;@txt-color-5: #DC3545;@txt-color-6: #747c8b;@txt-color-9: #aaa;@border-color-1: black;@border-color-2: #d9d9d9;@border-color-4: #dddddd;@border-color-8: #000000;@btn-bg-color-1: #ebb23d;@btn-bg-color-3: #e79235;@button-text: #ffffff;@menu-txt-color: #666666;