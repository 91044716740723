.dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 35px;

    .dashboard-item-container {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr; 
        gap: 10px;

        .stat-container {
            border-radius: 5px;

            .ant-collapse {
                border-radius: 5px;
                box-shadow: 0 0.25rem 0.25rem 0 rgba(0,0,0,.1);
            }

            .ant-collapse-header-text {
                border-left: 5px solid @txt-color-6;
                padding-left: 8px;
                font-weight: bold;
            }
        }

        .dashboard-commission-container {
            background-color: @background-color-4;
        }

        .dashboard-active-container {
            background-color: @background-color-1;
        }

        .dashboard-commission-container,
        .dashboard-active-container {
            border-top: 3px solid @txt-color-6;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            padding: 20px 15px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;

            .commission-title {
                font-size: 16px;
                font-weight: bold;
            }

            .commission-container {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                gap: 15px;

                .commission-period {
                    font-size: 16px;
                    color: @txt-color-6;
                    font-weight: 500;
                }

                .commission-amt {
                    font-size: 30px;
                }
            }
        }
    }
}
@primary-color: #046CC1;@secondary-color: #ffce54;@link-color: #1890ff;@background-color: #ffffff;@background-color-1: lightblue;@background-color-2: gainsboro;@background-color-3: blueviolet;@background-color-4: antiquewhite;@background-color-5: limegreen;@background-color-8: #000000;@background-color-9: #040162;@txt-color-1: black;@txt-color-2: white;@txt-color-3: blue;@txt-color-4: #198754;@txt-color-5: #DC3545;@txt-color-6: #747c8b;@txt-color-9: #aaa;@border-color-1: black;@border-color-2: #d9d9d9;@border-color-4: #dddddd;@border-color-8: #000000;@btn-bg-color-1: #ebb23d;@btn-bg-color-3: #e79235;@button-text: #ffffff;@menu-txt-color: #666666;